import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

const fetchClipartCategories = async () => {
    const { data } = await window.axios.get(route('categories.paginate', {
        type: 'cliparts',
        parents: 1,
        active: 1,
        per_page: 200
    }));
    return data.data;
};

const useClipartCategories = () => {
    const queryClient = useQueryClient();

    const { data: categories = [] } = useQuery({
        queryKey: ['clipartCategories'],
        queryFn: fetchClipartCategories,
        initialData: () => {
            const storedCategories = localStorage.getItem('cliparts-categories');
            return storedCategories ? JSON.parse(storedCategories) : undefined;
        },
        onSuccess: (data) => {
            localStorage.setItem('cliparts-categories', JSON.stringify(data));
        },
        onError: (error) => {
            console.error('Error fetching clipart categories:', error);
        },
    });

    useEffect(() => {
        const channel = window.Echo.channel(`cliparts-categories`);
        channel.listen('.cliparts-categories-updated-event', () => {
            queryClient.invalidateQueries(['clipartCategories']);
        });

        return () => {
            channel.stopListening('cliparts-categories-updated-event');
            window.Echo.leaveChannel(`cliparts-categories`);
        };
    }, [queryClient]);

    return { categories };
};


const fetchProductCategories = async () => {
    const { data } = await window.axios.get(route('categories.paginate', {
        type: 'products',
        parents: 1,
        active: 1,
        per_page: 200
    }));
    return data.data;
};

const useProductCategories = () => {
    const queryClient = useQueryClient();

    const { data: categories = [] } = useQuery({
        queryKey: ['productCategories'],
        queryFn: fetchProductCategories,
        initialData: () => {
            const storedCategories = localStorage.getItem('products-categories');
            return storedCategories ? JSON.parse(storedCategories) : undefined;
        },
        onSuccess: (data) => {
            localStorage.setItem('products-categories', JSON.stringify(data));
        },
    });

    useEffect(() => {
        const channel = window.Echo.channel(`products-categories`);
        channel.listen('.products-categories-updated-event', () => {
            queryClient.invalidateQueries(['productCategories']);
        });

        return () => {
            channel.stopListening('products-categories-updated-event');
            window.Echo.leaveChannel(`products-categories`);
        };
    }, [queryClient]);

    return { categories };
};


export { useClipartCategories, useProductCategories };
